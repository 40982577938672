const env = Object.freeze({
	buildVersion: process.env.REACT_APP_VERSION,
	environment: process.env.REACT_APP_ENV,
	isProduction: process.env.REACT_APP_ENV === "production",
	isAcceptance: process.env.REACT_APP_ENV === "acceptance",
	isTestServer: process.env.REACT_APP_ENV === "testserver",
	isDevelopment: process.env.REACT_APP_ENV === "development",
	debug: ["development", "testserver"].includes(
		process.env.REACT_APP_ENV || "",
	),
	apiUrl: process.env.REACT_APP_API_URL,
	isSixt: process.env.REACT_APP_IS_SIXT === "true",
});

export default env;
