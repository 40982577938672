import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { useDispatch, useSelector } from 'react-redux'

import FormRender from '@data-driven-forms/react-form-renderer/dist/cjs/form-renderer'
import FormTemplate from '@data-driven-forms/pf4-component-mapper/dist/cjs/form-template'
import componentMapper from '@data-driven-forms/pf4-component-mapper/dist/cjs/component-mapper'
import { Button } from 'react-bootstrap'
import { getCurrentUser, selectUser } from './authSlice'
import { showErrorNotification, showNotification } from '../notifications/notificationsSlice'
import Header from '../../components/Header'
import api from '../../utils/api'
import { UserType } from './types'
import ManageUserPermissions from './ManageUserPermissions'
import useRouteGuard from '../../hooks/useRouteGuard'
import { Link } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

const Verify2FA = ({ url, resetUrl }: { url: string; resetUrl: () => void }) => {
  const dispatch = useDispatch()
  const [QRUrl, setQRUrl] = useState('')

  useEffect(() => {
    ;(async () => {
      setQRUrl(await QRCode.toDataURL(url))
    })()
  }, [url])

  const fields = [
    {
      component: 'text-field',
      name: 'token',
      label: '2FA',
      type: 'token',
    },
  ]

  const onSubmit = async (data) => {
    const response = await api.post('/2fa/verify/', data)
    if (response.code > 300) {
      dispatch(showErrorNotification(response.error || 'Something went wrong'))
    } else {
      dispatch(showNotification({ body: 'Done!' }))
      dispatch(getCurrentUser())
      resetUrl()
    }
  }

  return (
    <div>
      <Header>Verify</Header>

      <p>Scan the QR below with your 2FA app (eg "Google Authenticator"), and fill the code below.</p>

      <img src={QRUrl} alt="qr" style={{ maxWidth: 400, width: '80vw', aspectRatio: '1 / 1' }} />

      <br />
      <p style={{ overflowWrap: 'break-word' }}>Link: {url}</p>
      <br />

      <FormRender
        schema={{ fields }}
        onSubmit={onSubmit}
        FormTemplate={FormTemplate}
        componentMapper={componentMapper}
      />
    </div>
  )
}

const Get2FA = ({ setUrl }: { setUrl: (value: string) => void }) => {
  const dispatch = useDispatch()

  const onClick = async () => {
    const response = await api.get('/2fa/create/')
    if (response.code > 300) {
      dispatch(showErrorNotification(response.error || 'Something went wrong'))
    } else {
      setUrl(response.data)
    }
  }
  return <Button onClick={onClick}>Set 2FA</Button>
}

const Set2FA = () => {
  const [url, setUrl] = useState('')
  if (url) {
    return <Verify2FA url={url} resetUrl={() => setUrl('')} />
  }
  return <Get2FA setUrl={setUrl} />
}

const Profile = ({ user }: { user: UserType }) => (
  <p>
    You are currently logged in with email: {user.email}
    <br />
    You {user.enabled2fa ? '' : 'do not'} have 2FA enabled
    <br />
    <br />
  </p>
)

const SecurityView = () => {
  useRouteGuard(undefined, 'canViewSecurity')
  const user = useSelector(selectUser)
  return (
    <div style={{ padding: 40 }}>
      <Profile user={user} />
      {user.enabled2fa ? (
        <Link to="/">
          <ArrowBack /> Home
        </Link>
      ) : (
        <Set2FA />
      )}
      <br />
      <br />
      {user.isSuperuser && <ManageUserPermissions />}
    </div>
  )
}

export default SecurityView
