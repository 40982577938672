import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, Polyline, PolylineProps, useLoadScript } from '@react-google-maps/api'
import { format } from 'date-fns'
import { Box } from '@mui/material'
import FullPageLoader from '../../components/FullPageLoader'
import styled from 'styled-components'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import { TrackerLocationHistory, TrackerMapProps } from './types'
import { LocationHistory } from '../map/types'
import { DataGrid, GridColDef, GridRowSelectionModel, GridValueFormatterParams } from '@mui/x-data-grid'
import api from '../../utils/api'
import { generateLabelForRepeatedMarkers, getRepeatedMarkers, sanitizeLocations } from '../map/utils'

const S = {
    FilterSidebar: styled.div<{ open?: boolean }>`
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background: #0a1929;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: 10;
        transform: ${({ open }) => (open ? 'tranlateX(0)' : 'translateX(100%)')};
        transition: transform 0.3s;
    `,
    ToggleSidebar: styled.div`
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 80px;
        border-radius: 10px 0 0 10px;
        background: #0a1929;
        box-shadow: -5px 0 10px rgba(0, 0, 0, 0.3);
    `,
}

const TrackerMap = React.memo(function Map({
    markers,
    selectedMarkerId,
    handleClickVehicle,
}: TrackerMapProps) {
    const selectedMarker = markers.find((item) => item.imei === selectedMarkerId)
    const [openSidebar, setOpenSidebar] = useState(true)
    const [prevLocations, setPrevLocations] = useState<LocationHistory[]>([])
    const [filteredLocations, setFilteredLocations] = useState<LocationHistory[]>([])
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

    useEffect(() => {
        setFilteredLocations(prevLocations.filter((e) => rowSelectionModel.includes(e.id)))
    }, [rowSelectionModel.join('__')])

    const columns: GridColDef[] = [
        { field: 'id', hideable: true },
        {
            field: 'date',
            headerName: 'Time',
            width: 120,
            filterable: false,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (!params.value) {
                    return ''
                }
                return format(new Date(params.value), 'MMM dd - HH:mm')
            },
        },
        { field: 'lat', headerName: 'Lat', width: 80 },
        { field: 'long', headerName: 'Long', width: 80 },
    ]

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    })
    const repeatedLocations = getRepeatedMarkers(filteredLocations)

    useEffect(() => {
        async function fetchLocations() {
            const res = await api.get<TrackerLocationHistory[]>(
                `/map-trackers/${selectedMarkerId}/locations/?limit=75`
            )
            const sanitizedData = sanitizeLocations(
                res.data.map((e) => ({
                    id: e.id,
                    lat: e.lat,
                    long: e.long,
                    date: e.dateTime || e.createdAt,
                }))
            )
            setPrevLocations(sanitizedData)
            setFilteredLocations(sanitizedData.slice(0, 10))
            setRowSelectionModel(sanitizedData.slice(0, 10).map((e) => e.id))
        }
        if (selectedMarkerId) {
            fetchLocations()
        }
    }, [selectedMarkerId])

    if (!isLoaded) {
        return <FullPageLoader />
    }

    const options: PolylineProps['options'] = {
        strokeColor: '#FF0000',
        strokeOpacity: 1,
        strokeWeight: 1.5,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        path: filteredLocations
            .map((e) => ({
                lat: e.lat,
                lng: e.long,
            }))
            .reverse(),
        zIndex: 1,
        icons: [
            {
                icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                offset: '100%',
                repeat: '25px',
            },
        ],
    }

    return (
        <Box width="100%" height="100%" position="relative">
            <GoogleMap
                zoom={11}
                center={{ lat: selectedMarker?.lat || 44, lng: selectedMarker?.long || -80 }}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                options={{ fullscreenControl: false }}
            >
                {filteredLocations.map((location) => (
                    <Marker
                        position={{ lat: location.lat, lng: location.long }}
                        label={{
                            text: format(new Date(location.date), 'MMM dd - HH:mm'),
                            className: 'marker-label',
                        }}
                        key={location.id}
                        icon="http://labs.google.com/ridefinder/images/mm_20_purple.png"
                    />
                ))}

                {repeatedLocations.map((location) => (
                    <Marker
                        position={{ lat: location.lat, lng: location.long }}
                        label={{
                            text: generateLabelForRepeatedMarkers(location),
                            className: 'marker-label',
                        }}
                        key={location.id}
                        icon="http://labs.google.com/ridefinder/images/mm_20_purple.png"
                    />
                ))}
                {markers.map((marker) => (
                    <Marker
                        position={{ lat: marker.lat, lng: marker.long }}
                        onClick={() => handleClickVehicle(marker)}
                        label={{ text: marker.name || marker.imei, className: 'marker-label' }}
                        key={marker.imei}
                    />
                ))}
                <Polyline options={options} />
            </GoogleMap>
            {selectedMarkerId && (
                <S.FilterSidebar open={openSidebar}>
                    <S.ToggleSidebar onClick={() => setOpenSidebar(!openSidebar)}>
                        <KeyboardArrowLeft
                            fontSize="large"
                            sx={{ color: 'white', transform: openSidebar ? 'rotate(180deg)' : 'rotate(0)' }}
                        />
                    </S.ToggleSidebar>
                    <DataGrid
                        rows={prevLocations}
                        columns={columns}
                        columnVisibilityModel={{
                            id: false,
                        }}
                        checkboxSelection
                        density="compact"
                        sx={{ color: 'white', '& svg': { fill: 'white' } }}
                        disableColumnMenu
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel)
                        }}
                        rowSelectionModel={rowSelectionModel}
                        hideFooterPagination
                        hideFooter
                        disableRowSelectionOnClick
                    />
                </S.FilterSidebar>
            )}
        </Box>
    )
})

export default TrackerMap
