import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import { Container } from '@mui/system'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ImageUpload from '../../../components/ImageUpload'
import useRouteGuard from '../../../hooks/useRouteGuard'
import api from '../../../utils/api'
import { showErrorNotification, showNotification } from '../../notifications/notificationsSlice'
import { branchOptions, locatedCountryOptions } from '../formConfig'

export const requestOptions = [
  { label: 'Stolen vehicle search & seizure', value: 'normal' },
  { label: 'Assist with breakdown', value: 'assist_breakdown' },
  { label: 'Recover from storage', value: 'recover_storage' },
]

const RentalReportForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <Formik
      initialValues={{
        branch: undefined,
        renterName: undefined,
        locatedCountry: undefined,
        plate: undefined,
        vinNumber: undefined,
        model: undefined,
        make: undefined,
        note: undefined,
        theftReport: undefined,
        vehicleRegistrationPapers: undefined,
        insuranceDocs: undefined,
        authorizationToTowDocs: undefined,
        rentalReport: {
          requestType: requestOptions[0].value,
          lastKnownAddress: undefined,
          trackerFitted: false,
          trackerUrl: '',
          lastKnownLat: undefined,
          lastKnownLong: undefined,
          powerOfAttorney: undefined,
          rentalAgreement: undefined,
          renterIdDocs: undefined,
          rentalAgreementCancellationDocs: undefined,
          clientPhoneNumber: undefined,
          breakdownLocation: undefined,
          storageLocation: undefined,
          storagePhoneNumber: undefined,
          storageEmail: undefined,
        },
      }}
      enableReinitialize
      onSubmit={async (values) => {
        values = {
          ...values,
          theftReport: values.theftReport?.map?.((image) => image.id),
          vehicleRegistrationPapers: values.vehicleRegistrationPapers?.map?.((image) => image.id),
          insuranceDocs: values.insuranceDocs?.map?.((image) => image.id),
          authorizationToTowDocs: values.authorizationToTowDocs?.map?.((image) => image.id),
          rentalReport: {
            ...values.rentalReport,
            powerOfAttorney: values.rentalReport.powerOfAttorney?.map?.((image) => image.id),
            rentalAgreement: values.rentalReport.rentalAgreement?.map?.((image) => image.id),
            rentalAgreementCancellationDocs: values.rentalReport.rentalAgreementCancellationDocs?.map?.(
              (image) => image.id
            ),
            renterIdDocs: values.rentalReport.renterIdDocs?.map?.((image) => image.id),
          },
        }
        const { error } = await api.post(`/vehicles/rental_report/`, values)
        if (error) {
          return dispatch(showErrorNotification(error || 'Something went wrong'))
        }
        dispatch(
          showNotification({
            type: 'success',
            body: 'Created rental report successfully',
          })
        )
        history.push('/vehicles')
      }}
    >
      {({ values, handleChange, isSubmitting }) => (
        <Form>
          <Box display="grid" gap="24px">
            <FormControl size="small">
              <InputLabel id="requestType">Request</InputLabel>
              <Select
                labelId="requestType"
                id="requestType"
                name="rentalReport.requestType"
                value={values.rentalReport.requestType}
                label="Request"
                onChange={handleChange}
              >
                {requestOptions.map((e) => (
                  <MenuItem value={e.value}>{e.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="branch">Branch</InputLabel>
              <Select
                labelId="branch"
                id="branch"
                name="branch"
                value={values.branch}
                label="Branch"
                onChange={handleChange}
              >
                {branchOptions.map((e) => (
                  <MenuItem value={e.value}>{e.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {values.rentalReport.requestType === 'assist_breakdown' && (
              <>
                <h3>Breakdown Assist</h3>
                <TextField
                  fullWidth
                  id="clientPhoneNumber"
                  name="rentalReport.clientPhoneNumber"
                  label="Client phone number"
                  size="small"
                  value={values.rentalReport.clientPhoneNumber}
                  onChange={handleChange}
                  type="tel"
                />
                <TextField
                  fullWidth
                  id="breakdownLocation"
                  name="rentalReport.breakdownLocation"
                  label="Breakdown Location"
                  size="small"
                  value={values.rentalReport.breakdownLocation}
                  onChange={handleChange}
                />
              </>
            )}

            {values.rentalReport.requestType === 'recover_storage' && (
              <>
                <h3>Recover from storage</h3>
                <TextField
                  fullWidth
                  id="storageLocation"
                  name="rentalReport.storageLocation"
                  label="Storage Location"
                  size="small"
                  value={values.rentalReport.storageLocation}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  id="storagePhoneNumber"
                  name="rentalReport.storagePhoneNumber"
                  label="Storage phone number"
                  size="small"
                  value={values.rentalReport.storagePhoneNumber}
                  onChange={handleChange}
                  type="tel"
                />
                <TextField
                  fullWidth
                  id="storageEmail"
                  name="rentalReport.storageEmail"
                  label="Storage email"
                  size="small"
                  value={values.rentalReport.storageEmail}
                  onChange={handleChange}
                  type="email"
                />
              </>
            )}

            <TextField
              fullWidth
              id="plate"
              name="plate"
              label="Plate"
              size="small"
              value={values.plate}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              id="vin"
              name="vinNumber"
              label="VIN"
              size="small"
              value={values.vinNumber}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              id="make"
              name="make"
              label="Make"
              size="small"
              value={values.make}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              id="model"
              name="model"
              label="Model"
              size="small"
              value={values.model}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              id="note"
              name="note"
              label="Intelligence"
              size="small"
              value={values.note}
              onChange={handleChange}
              multiline
              rows={5}
            />
            <TextField
              fullWidth
              id="renterName"
              name="renterName"
              label="Renter name"
              size="small"
              value={values.renterName}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              id="lastKnownAddress"
              name="rentalReport.lastKnownAddress"
              label="Last Known Address"
              size="small"
              value={values.rentalReport.lastKnownAddress}
              onChange={handleChange}
            />
            <FormControl size="small">
              <InputLabel id="locatedCountry">Located Country</InputLabel>
              <Select
                labelId="locatedCountry"
                id="locatedCountry"
                name="locatedCountry"
                value={values.locatedCountry}
                label="Located Country"
                onChange={handleChange}
              >
                {locatedCountryOptions.map((e) => (
                  <MenuItem value={e.value}>{e.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={values.rentalReport.trackerFitted}
                  onChange={handleChange}
                  name="rentalReport.trackerFitted"
                />
              }
              label="Tracker Fitted"
            />
            {values.rentalReport.trackerFitted && (
              <>
                <TextField
                  fullWidth
                  id="trackerUrl"
                  name="rentalReport.trackerUrl"
                  label="Tracker URL"
                  size="small"
                  value={values.rentalReport.trackerUrl}
                  onChange={handleChange}
                />
                <div style={{ display: 'flex', gap: 16 }}>
                  <TextField
                    id="trackerUrl"
                    name="rentalReport.lastKnownLat"
                    label="Last known lat"
                    size="small"
                    value={values.rentalReport.lastKnownLat}
                    onChange={handleChange}
                  />
                  <TextField
                    id="trackerUrl"
                    name="rentalReport.lastKnownLong"
                    label="Last known long"
                    size="small"
                    value={values.rentalReport.lastKnownLong}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}

            <ImageUpload name="rentalReport.powerOfAttorney" label="POA" isMulti />
            <ImageUpload name="theftReport" label="Theft Report" isMulti />
            <ImageUpload name="rentalReport.renterIdDocs" label="Renter Id Documents" isMulti />
            <ImageUpload name="rentalReport.rentalAgreement" label="Rental Agreement" isMulti />
            <ImageUpload
              name="rentalReport.rentalAgreementCancellationDocs"
              label="Rental Agreement Cancellation Documents"
              isMulti
            />
            <ImageUpload name="vehicleRegistrationPapers" label="Vehicle Registration Papers" isMulti />
            <ImageUpload name="insuranceDocs" label="Insurance Documents" isMulti />
            <ImageUpload name="authorizationToTowDocs" label="Authorization to Tow Document" isMulti />

            <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
              Submit
              {isSubmitting && <CircularProgress size={16} color="secondary" sx={{ ml: 2 }} />}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const RentalReportView = () => {
  useRouteGuard('canReportRental', 'canViewRentalReport')

  return (
    <Container maxWidth="md" sx={{ p: 2, mt: 2 }}>
      <Paper sx={{ p: 2 }}>
        <RentalReportForm />
      </Paper>
    </Container>
  )
}

export default RentalReportView
