import FormRender from "@data-driven-forms/react-form-renderer/dist/cjs/form-renderer";
import FormTemplate from "@data-driven-forms/pf4-component-mapper/dist/cjs/form-template";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NewVehicleType, VehicleType } from "../types";
import { componentMapper, fields, validatorMapper } from "../formConfig";
import api from "../../../utils/api";
import {
	showErrorNotification,
	showNotification,
} from "../../notifications/notificationsSlice";
import { formatNlDateTime, nlToIsoDateTime } from "../../../utils/datetime";
import { setIsPageLoading } from "../../../appSlice";

const schema = { fields };

const defaults = { digitpolAtSeizure: true };

const getSchema = (fields?: string[]) =>
	fields
		? {
				...schema,
				fields: schema.fields.filter(({ name }) => fields.includes(name)),
			}
		: schema;

interface VehicleFormProps {
	vehicle?: VehicleType | NewVehicleType;
	fields?: string[];
}

const toFormValues = (vehicle) => ({
	...vehicle,
	datetimeSeized: formatNlDateTime(vehicle.datetimeSeized),
	dateOfTheft: formatNlDateTime(vehicle.dateOfTheft),
});

const VehicleForm = ({
	vehicle = { ...defaults },
	fields = null,
}: VehicleFormProps) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const goHome = () => history.push("/");
	const goToVehicle = (id: number) => history.push(`/vehicles/${id}`);

	const onSubmit = async (data) => {
		try {
			await submitForm(data);
		} catch (e) {
			console.error(e);
		}
	};

	const getIds = (files = []) => files.map(({ id }) => id);

	const submitForm = async (_data) => {
		dispatch(setIsPageLoading(true));
		console.log("raw data", _data);
		const data = {
			..._data,
			datetimeSeized: nlToIsoDateTime(_data.datetimeSeized),
			dateOfTheft: nlToIsoDateTime(_data.dateOfTheft),
			// For file, only send ID
			seizureReports: getIds(_data.seizureReports),
			recoveryReports: getIds(_data.recoveryReports),
			vehicleRegistrationPapers: getIds(_data.vehicleRegistrationPapers),
			insuranceDocs: getIds(_data.insuranceDocs),
			authorizationToTowDocs: getIds(_data.authorizationToTowDocs),
			images: getIds(_data.images),
			files: getIds(_data.files),
			theftReport: getIds(_data.theftReport),
			screenshots: getIds(_data.screenshots),
		};
		console.log("formatted", data);
		// Remove excluded fields
		Object.keys(data).forEach((key) => {
			if (fields && !fields.includes(key)) {
				delete data[key];
			}
		});

		if (data.rdw) {
			delete data.rdw;
		}

		const response =
			"id" in vehicle
				? await api.patch<VehicleType>(`/vehicles/${vehicle.id}/`, data)
				: await api.post<VehicleType>("/vehicles/", data);

		dispatch(setIsPageLoading(false));

		if (response.code > 300) {
			dispatch(showErrorNotification(response.error || "Something went wrong"));
		} else {
			const created = response.code === 201;
			dispatch(
				showNotification({
					body: created ? "Record Created" : "Record Updated",
				}),
			);
			goToVehicle(response.data.id);
		}
	};

	return (
		<div style={{ maxWidth: 770, margin: "auto", padding: 24, paddingTop: 0 }}>
			<FormRender
				initialValues={toFormValues(vehicle)}
				schema={getSchema(fields)}
				onSubmit={onSubmit}
				onCancel={goHome}
				FormTemplate={FormTemplate}
				validatorMapper={validatorMapper}
				componentMapper={componentMapper}
			/>
		</div>
	);
};

export default VehicleForm;
