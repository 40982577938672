import styled, { css } from 'styled-components'
import uniqBy from 'lodash/uniqBy'
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { color } from '../constants'
import { selectUser } from '../features/users/authSlice'
import { UserType } from '../features/users/types'
import env from '../constants/env'

const S = {
    AppBar: styled(AppBar)`
        @media print {
            display: none !important;
        }
    `,
    Logo: styled.img`
        max-width: 200px;
        height: 60px;
        object-fit: contain;
        object-position: left bottom;
        flex-shrink: 0;
        border-radius: 4px;
        margin-right: 10px;
    `,
    NavItem: styled(Link)<{ $active?: boolean }>`
        display: inline-block;
        color: white;
        text-decoration: none;
        margin: 0 4px;
        padding: 4px 8px;
        white-space: nowrap;
        border-radius: 4px;
        user-select: none;
        transition: 0.2s;
        font-weight: 600;

        &:hover {
            background: white;
            color: ${color.primary};
            text-decoration: none;
        }

        ${({ $active }) =>
            $active &&
            css`
                background: white;
                color: ${color.primary};
                text-decoration: none;
            `}
    `,
}

export const getNavItems = (user: UserType) =>
    uniqBy(
        [
            { link: '/dashboard/', text: 'Dashboard', auth: 'canReadVehicles', viewRole: 'canViewDashboard' },
            { link: '/rdw/', text: 'RDW', auth: 'canReadVehicles', viewRole: 'canViewRdw' },
            {
                link: '/rdw/plate-search/',
                text: 'Plate Search',
                auth: 'canReadVehicles',
                viewRole: 'canViewPlateSearch',
            },
            { link: '/vehicles/', text: 'Database', auth: 'canReadVehicles', viewRole: 'canViewDatabase' },
            {
                link: '/vehicles/',
                text: 'Database',
                auth: 'canReportRental',
                viewRole: 'canViewRentalReport',
            },
            { link: '/map/', text: 'Map', auth: 'canReadVehicles', viewRole: 'canViewDatabase' },
            { link: '/map/', text: 'Map', auth: 'canReportRental', viewRole: 'canViewRentalReport' },
            { link: '/trackers/', text: 'Trackers', auth: 'canReadVehicles', viewRole: 'canViewTrackers' },
            { link: '/trackers/', text: 'Trackers', auth: 'canReportRental', viewRole: 'canViewRentalReport' },
            {
                link: '/thefts-per-street/',
                text: 'Thefts Per Street',
                auth: 'canReadVehicles',
                viewRole: 'canViewThefts',
            },
            {
                link: '/new-seizure',
                text: 'New Seizure',
                auth: 'canReportSeizure',
                viewRole: 'canViewNewSeizure',
            },
            {
                link: '/report-for-search',
                text: 'Report for search',
                auth: 'canReportMissing',
                viewRole: 'canViewReportForSearch',
            },
            {
                link: '/rental-report',
                text: env.isSixt ? 'New Case' : 'Rental Report',
                auth: 'canReportRental',
                viewRole: 'canViewRentalReport',
            },
            { link: '/web-hits', text: 'Web HITS', auth: 'canReadVehicles', viewRole: 'canViewWebhits' },
            { link: '/vin-search', text: 'Vin search', auth: 'canSearchVin', viewRole: 'canSearchVin' },
            {
                link: '/wok-request',
                text: 'WOK request',
                auth: 'canReportWok',
                viewRole: 'canViewWokRequest',
            },
            {
                link: '/register-container',
                text: 'Register container',
                auth: 'canRegisterContainer',
                viewRole: 'canViewContainers',
            },
            {
                link: '/containers',
                text: 'Cargo Intelligence',
                auth: 'canViewContainers',
                viewRole: 'canViewContainers',
            },
            { link: '/security', text: 'Security', auth: 'id', viewRole: 'canViewSecurity' },
            { link: '/logout', text: 'Logout', auth: 'id' },
            {
                link: '/repatriation',
                text: 'Repatriation',
                auth: 'canViewRepatriation',
                viewRole: 'canViewRepatriation',
            },
        ]
            .filter((route) => user.isSuperuser || user[route.auth])
            .filter((route) => !route.viewRole || user[route.viewRole] || user.isSuperuser),
        'link'
    )
const TopNav = () => {
    const user = useSelector(selectUser)
    const history = useHistory()
    const navItems = getNavItems(user)
    const isActive = ({ link }) => {
        return history.location.pathname.replaceAll('/', '') === link.replaceAll('/', '')
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (link) => {
        setAnchorEl(null)
        history.push(link)
    }

    return (
        <S.AppBar>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <S.Logo src={user.organization ? user.organization.logo : '/logo.png'} />
                <Typography variant="h6" color="white">
                    {navItems.find(isActive)?.text || ''}
                </Typography>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleClick}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {navItems.map(({ link, text }) => (
                        <MenuItem onClick={() => handleClose(link)} sx={{ color: color.primary }} key={link}>
                            {text}
                        </MenuItem>
                    ))}
                </Menu>
            </Toolbar>
        </S.AppBar>
    )
}

export default TopNav
