import { Card, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import './app.css'
import { BrowserRouter, Link, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { Nav, NavList, PageHeader } from '@patternfly/react-core'
import styled from 'styled-components'
import React from 'react'
import preval from 'preval.macro'
import NotificationsContainer from './features/notifications/NotificationsContainer'
import { selectIsAuthenticated, selectIsLoadingAuth, selectUser } from './features/users/authSlice'
import LoginView from './features/users/LoginView'
import SecurityView from './features/users/SecurityView'
import LogoutView from './features/users/LogoutView'
import TableView from './features/vehicles/views/TableView'
import ReportSeizureView from './features/vehicles/views/ReportSeizureView'
import ReportForSearchView from './features/vehicles/views/ReportForSearchView'
import RepatriationView from './features/vehicles/views/RepatriationView'
import TheftsPerStreet from './features/vehicles/views/TheftsPerStreet'
import VehicleView from './features/vehicles/views/VehicleView'
import VehicleEditView from './features/vehicles/views/VehicleEditView'
import ReportView from './features/reports/ReportView'
import Dashboard from './features/dashboard/Dashboard'
import TheftsOnStreet from './features/vehicles/views/TheftsOnStreet'
import RdwList from './features/rdw/views/RdwList'
import RdwPlateSearch from './features/rdw/views/RdwPlateSearch'
import RdwDetails from './features/rdw/views/RdwDetails'
import WOKRequestView from './features/vehicles/views/WOKRequestView'
import RentalReportView from './features/vehicles/views/RentalReportView'
import { FullPageLoaderWithSlice } from './components/FullPageLoader'
import VinSearch from './features/vin-search/VinSearch'
import TopNav, { getNavItems } from './components/TopNav'
import WebHITS from './features/web-hits/WebHITS'
import * as Sentry from '@sentry/react'
import MapView from './features/map/MapView'
import TrackerMapView from './features/trackers/TrackerMapView'
import ContainerList from './features/containers/ContainerList'
import ContainerForm from './features/containers/ContainerForm'
import ContainerDetail from './features/containers/ContainerDetail'

const S = {
  App: styled.div`
    min-height: 100vh;
    overflow-y: auto;
    background: #fbfbfb;
  `,
  Page: styled.div`
    max-width: 100vw;
    padding-top: 64px;
    padding-bottom: 24px;
    overflow-x: auto;
    background: #fbfbfb;
    @media print {
      padding-top: 10px;
    }
  `,
  Home: styled.div`
    margin-top: -80px;
    padding: 20%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  `,
  Logo: styled.img`
    height: 50px;
    @media print {
      display: none;
    }
  `,
  HomeLink: styled(Link)`
    margin: auto;
    padding: 12px;
    min-width: 240px;
    text-align: center;
    @media print {
      display: none;
    }
  `,
  LinkTitle: styled(Card.Title)`
    margin: auto;
    padding: 30px 0;
  `,
  ReleaseInfo: styled.p`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    font-size: 12px;
    background: white;
    padding: 0 6px;
    @media print {
      display: none;
    }
  `,
}

const requireLogin = (fn: (...args: any) => any) => {
  const Wrapper = ({ children }: any) => {
    const isLoadingAuth = useSelector(selectIsLoadingAuth)
    const isAuthenticated = useSelector(selectIsAuthenticated)

    if (isLoadingAuth) {
      return <Spinner animation="border" style={{ margin: '40%' }} />
    }

    if (!isAuthenticated) {
      return <Redirect to="/login/" />
    }
    return children
  }

  return (...args: any) => <Wrapper>{fn(...args)}</Wrapper>
}

const HomeLink = ({ to, children }: { to: string; children: any }) => (
  <S.HomeLink to={to}>
    <Card>
      <Card.Body>
        <S.LinkTitle>{children}</S.LinkTitle>
      </Card.Body>
    </Card>
  </S.HomeLink>
)

const Home = () => {
  const user = useSelector(selectUser)
  const isLoadingAuth = useSelector(selectIsLoadingAuth)
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (isLoadingAuth) {
    return <Spinner animation="border" style={{ margin: '40%' }} />
  }

  if (!isAuthenticated) {
    return <Redirect to="/login/" />
  }

  if (!user.enabled2fa) {
    return <Redirect to="/security/" />
  }

  return (
    <>
      <S.Home>
        {getNavItems(user).map(({ link, text }) => (
          <HomeLink to={link} key={link}>
            {text}
          </HomeLink>
        ))}
      </S.Home>
    </>
  )
}

const AuthenticatedApp = () => (
  <>
    <TopNav />
    <S.Page>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/rdw/" exact component={RdwList} />
          <Route path="/rdw/search/:plate/" exact component={RdwDetails} />
          <Route path="/rdw/plate-search/" exact component={RdwPlateSearch} />
          <Route path="/vin-search/" exact component={VinSearch} />
          <Route path="/web-hits/" exact component={WebHITS} />
          <Route path="/repatriation/" exact component={RepatriationView} />
          <Route path="/repatriation/:plate/" exact component={RepatriationView} />
          <Route path="/new-seizure/" exact component={ReportSeizureView} />
          <Route path="/vehicles/" exact component={TableView} />
          <Route path="/vehicles/:id/" exact component={VehicleView} />
          <Route path="/vehicles/:id/edit/" exact component={VehicleEditView} />
          <Route path="/vehicles/:id/:reportType/" exact component={ReportView} />
          <Route path="/map/" exact component={MapView} />
          <Route path="/report-for-search/" exact component={ReportForSearchView} />
          <Route path="/rental-report/" exact component={RentalReportView} />
          <Route path="/wok-request/" exact component={WOKRequestView} />
          <Route path="/dashboard/" exact component={Dashboard} />
          <Route path="/thefts-per-street/" exact component={TheftsPerStreet} />
          <Route path="/thefts-per-street/:streetFilter/:streetName" exact component={TheftsOnStreet} />
          <Route path="/logout/" exact component={LogoutView} />
          <Route path="/security/" exact component={SecurityView} />
          <Route path="/trackers/" exact component={TrackerMapView} />
          <Route path="/containers/" exact component={ContainerList} />
          <Route path="/containers/:id" exact component={ContainerDetail} />
          <Route path="/register-container/" exact component={ContainerForm} />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Sentry.ErrorBoundary>
    </S.Page>
  </>
)

const App = () => (
  <S.App>
    <BrowserRouter>
      <NotificationsContainer />
      <FullPageLoaderWithSlice />
      <Switch>
        <Route path="/login/" exact component={LoginView} />
        <Route component={requireLogin(AuthenticatedApp)} />
      </Switch>
    </BrowserRouter>

    <S.ReleaseInfo>Software Release Date: {preval`module.exports = new Date().toLocaleString();`}.</S.ReleaseInfo>
  </S.App>
)

export default App
